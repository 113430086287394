/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import useApiStatus, { ApiStatus } from '../use-api-status/useApiStatus';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

export type GitHubApiProps = {
  apiStatus: ApiStatus;
  query: string;
  payload?: any;
};

/**
 * A hook for using the Github graphql api through judge-api
 *
 */
const useGitHub = (q: string, isEnabled: boolean): [GitHubApiProps, (q: string) => void] => {
  const [apiStatus, setIsLoading, setHasError, setError] = useApiStatus();
  const [results, setApiResults] = useState({} as any);

  const [query, setQuery] = useState(q);
  const judgeApiProps: GitHubApiProps = { apiStatus, query };

  const getQuery = useCallback(async () => {
    if (!isEnabled) {
      return;
    }

    try {
      setIsLoading(true);
      setHasError(false);
      const response = await fetch(`/judge-api/github?query=${query}`, {
        method: 'GET',
        credentials: 'include',
      });

      const json = await response.json();
      setApiResults(json?.data);
      if (json?.errors) {
        setError(json?.errors);
        setHasError(true);
      }
    } catch (error) {
      setError(error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [isEnabled, query, setError, setHasError, setIsLoading]);

  useEffect(() => {
    if (apiStatus.error) {
      console.error(apiStatus.error);
    }
  }, [apiStatus.error]);

  useLayoutEffect(() => {
    if (query?.length >= 3) {
      void getQuery();
    }
  }, [getQuery, query?.length]);

  return [{ apiStatus: judgeApiProps.apiStatus, payload: results, query: query }, setQuery];
};

/**
 * A hook for using the Github graphql api through judge-api, for many queries at once
 * This isn't ideal, but there are a few entrypoints in github's graph that require multiple queries to get the data we need
 * @param queries - array of queries
 * @param isEnabled - boolean to enable the hook
 * @returns
 */
export const useGitHubMany = (queries: string[], isEnabled: boolean): [GitHubApiProps[], (queries: string[]) => void] => {
  const [apiStatus, setIsLoading, setHasError, setError] = useApiStatus();
  const [responses, setResponses] = useState([] as any[]);
  const [results, setApiResults] = useState([] as GitHubApiProps[]);

  const [queryList, setQueryList] = useState(queries);
  const judgeApiPropsList: GitHubApiProps[] = queryList.map((query) => ({ apiStatus, query }));

  const getQueries = useCallback(async () => {
    if (!isEnabled) {
      return;
    }

    try {
      setIsLoading(true);
      setHasError(false);
      const responses = await Promise.all(
        queryList.map((query) =>
          fetch(`/judge-api/github?query=${query}`, {
            method: 'GET',
            credentials: 'include',
          }).then((response) => response.json())
        )
      );

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      const aggregatedResults = responses.map((response) => response?.data);
      setResponses(aggregatedResults);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      const errors = responses.filter((response) => response?.errors).map((response) => response?.errors);
      if (errors.length > 0) {
        setError(errors);
        setHasError(true);
      }
    } catch (error) {
      setError(error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [isEnabled, queryList, setError, setHasError, setIsLoading]);

  useLayoutEffect(() => {
    if (queryList.length > 0) {
      void getQueries();
    }
  }, [getQueries, queryList.length]);

  useLayoutEffect(() => {
    if (responses.length > 0) {
      setApiResults(judgeApiPropsList.map((props, index) => ({ ...props, payload: responses[index] })));
    }
    // NOTE: we need to disable exhaustive-deps here because we want to run this effect only when responses length changes, preventing too many rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responses.length]);

  return [results, setQueryList];
};

export default useGitHub;
